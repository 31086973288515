<template>
  <div id="form-auth" class="form-wrapper">
    <div class="d-flex align-center justify-center mb-10">
      <v-img
        :src="require('../assets/logoDealer24.svg')"
        max-width="270"
      ></v-img>
    </div>
    <div
      class="d-flex justify-center align-center flex-column my-7"
      v-if="authEsa"
    >
      <v-btn
        id="enter"
        elevation="1"
        color="privat"
        class="white--text mb-5"
        width="65%"
        height="39px"
        :disabled="loading"
        @click.native="goToAuthEsa"
      >
        <span>{{ $t("auth.title") }}</span>
      </v-btn>
      <v-btn
        id="back"
        elevation="1"
        width="47%"
        height="39px"
        :disabled="loading"
        @click="authEsa = false"
      >
        <span>{{ $t("action.back") }}</span>
      </v-btn>
    </div>
    <v-card class="my-3 rounded-1" v-else>
      <v-card-title class="justify-center pa-8 pb-4">
        {{ $t("auth.title") }}
      </v-card-title>
      <v-form
        v-model="valid"
        ref="formAuth"
        lazy-validation
        novalidate
        autocomplete="off"
        @submit.prevent="submitHandler"
        v-if="!authEsa"
      >
        <v-card-text class="px-8 py-0">
          <template v-if="!sentPhone">
            <span class="d-inline-block mb-5">
              {{ $t("auth.enterPhone") }}
            </span>
            <v-text-field
              id="userPhone"
              v-model="phone"
              :label="$i18n.t('label.phone')"
              :rules="rules.phoneRules"
              :disabled="loading"
              maxlength="13"
              ref="userPhone"
              color="privat"
              required
              outlined
              dense
              @keyup.enter="sendPhone"
            ></v-text-field>
          </template>
          <template>
            <span class="d-inline-block mb-5" v-if="sentPhone">
              {{ $t("auth.enterOtp", { tel: phone }) }}
            </span>
            <v-text-field
              id="userOtp"
              v-model="codeOtp"
              :label="$i18n.t('label.otp')"
              :rules="rules.codeOtp"
              :disabled="loading"
              maxlength="4"
              ref="userOtp"
              color="privat"
              autofocus
              required
              outlined
              dense
              @mousedown="isValidOtp = true"
              @keydown="isValidOtp = true"
              v-show="sentPhone"
            ></v-text-field>
          </template>
        </v-card-text>
        <v-card-actions
          class="px-8 pt-2 justify-space-between"
          :class="!sentPhone ? 'flex-column pb-4' : 'flex-row pb-6'"
        >
          <v-btn
            v-if="!sentPhone"
            id="continue"
            elevation="1"
            color="privat"
            class="white--text"
            width="100%"
            height="39px"
            :disabled="loading"
            @click.native="sendPhone"
          >
            <span style="width: 82%;">{{ $t("action.continue") }}</span>
          </v-btn>
          <v-btn
            v-if="sentPhone"
            id="back"
            elevation="1"
            width="47%"
            height="39px"
            :disabled="loading"
            @click="back"
          >
            <span>{{ $t("action.back") }}</span>
          </v-btn>
          <v-btn
            v-if="sentPhone"
            id="enter"
            elevation="1"
            color="privat"
            class="white--text"
            width="47%"
            height="39px"
            :disabled="loading"
            @click.native="sendOtp"
          >
            <span>{{ $t("action.enter") }}</span>
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-card-actions class="justify-center pt-4 pb-6" v-if="!sentPhone">
        <a class="privat--text" @click="authEsa = true">
          Зайти як співробітник банку
        </a>
      </v-card-actions>
    </v-card>

    <div class="personal-information mt-16">
      <a
        class="privat--text"
        href="https://privatbank.ua/personal-information"
        target="_blank"
        rel="noopener noreferrer"
      >
        Про персональні дані
      </a>
    </div>

    <base-snackbar :props="snack" :show="show" @close="show = false" />

    <v-progress-circular
      v-if="loading"
      :size="50"
      color="privat"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import showSnack from "@/mixins/showSnack.mixins";
import { mapGetters } from "vuex";
import CONST from "@/utils/constants";
import errorHandler from "@/utils/errorHandler";

export default {
  name: "login",
  mixins: [showSnack],
  data() {
    return {
      load: false,
      loading: false,
      valid: true,
      lifeToken: null,
      phone: "+380",
      sentPhone: false,
      codeOtp: null,
      isValidOtp: true,
      possibleDispatch: 3,
      usedDispatch: 0,
      authEsa: false
    };
  },
  computed: {
    ...mapGetters(["isLogged", "getUser", "getStatus"]),
    rules() {
      return {
        phoneRules: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            CONST.REG_EXP_PHONE.test(v) || this.$i18n.t("rules.phone.correct")
        ],
        codeOtp: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /^[0-9]{4}$/.test(v) || this.$i18n.t("rules.codeOtp.correct"),
          v =>
            (v && this.isValidOtp) ||
            this.$i18n.t("rules.codeOtp.badOtp", { counter: this.dispatchOtp })
        ]
      };
    },
    dispatchOtp() {
      let counter = this.possibleDispatch - this.usedDispatch;
      let text =
        counter === 1
          ? this.$i18n.t("app.attempt")
          : this.$i18n.t("app.attempts");
      return `${counter} ${text}`;
    }
  },
  methods: {
    submitHandler() {
      if (!this.sentPhone) {
        this.sendPhone();
      } else {
        this.sendOtp();
      }
    },
    back() {
      this.clearProps();
    },
    async sendPhone() {
      if (this.$refs.userPhone.validate()) {
        this.loading = true;

        await this.$store
          .dispatch("sendPhone", { phone: this.phone })
          .then(() => {
            if (this.getStatus === "SEND_PHONE") {
              this.sentPhone = true;
            } else {
              let errorCode = this.getStatus || "sendPhone";

              this.showSnack("error", [
                this.$i18n.t(`error.${errorCode}`, { phone: this.phone })
              ]);
              console.log("then sendPhone error");
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendPhone");

            this.showSnack("error", [
              this.$i18n.t(`error.${errorCode}`, { phone: this.phone })
            ]);
            console.log("catch sendPhone");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async sendOtp() {
      if (this.$refs.userOtp.validate()) {
        this.loading = true;

        await this.$store
          .dispatch("sendOtp", { otp: this.codeOtp })
          .then(() => {
            this.usedDispatch += 1;
            if (this.isLogged) {
              this.userInfo();
            } else {
              let errorCode = this.getStatus || "sendOtp";

              this.checkStateOtp(errorCode);
              console.log("then sendOtp error");
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendOtp");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.loading = false;
            console.log("catch sendOtp");
          });
      }
    },
    checkStateOtp(code) {
      if (code === "BAD" && this.usedDispatch <= 2) {
        this.isValidOtp = false;
        this.$refs.userOtp.validate();
      } else {
        this.clearProps();
        this.showSnack("error", [this.$i18n.t(`error.${code}`)]);
      }
      this.loading = false;
    },
    userInfo() {
      this.$store
        .dispatch("getUserInfo")
        .then(() => {
          let userInfo = this.getUser;

          if (!userInfo?.user) {
            let errorCode = this.getStatus || "ERROR_USER";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.loading = false;
            console.log("then userInfo error");
          } else {
            this.$router.push("/orders");
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "ERROR_USER");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch userInfo");
        })
        .finally(() => (this.loading = false));
    },
    clearProps() {
      this.isValidOtp = true;
      this.usedDispatch = 0;
      this.sentPhone = false;
      this.phone = "+380";
      this.codeOtp = null;
      this.$store.commit("auth_error", "");
    },
    goToAuthEsa() {
      window.open(`${this.urlBase}/esa/pb`);
    }
  },
  mounted() {
    this.urlBase = window.location.origin;
    this.authEsa = this.$route.path.includes("authEsa");
    switch (this.$route.query.message) {
      case "sessionOut":
        this.showSnack("warning", [this.$i18n.t("warning.sessionOut")]);
        break;
      case "logout":
        this.showSnack("success", [this.$i18n.t("success.logout")]);
        break;
    }
  }
};
</script>

<style lang="scss">
#form-auth {
  position: relative;
  .v-sheet.v-card {
    box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.02),
      0 16px 32px 0 rgba(0, 0, 0, 0.02), 0 12px 16px 0 rgba(0, 0, 0, 0.02),
      0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 2px 8px 0 rgba(0, 0, 0, 0.08),
      0 0 4px 0 rgba(0, 0, 0, 0.04);
  }
  .v-progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.personal-information {
  text-align: center;
  a {
    text-decoration: none;
  }
}
</style>
